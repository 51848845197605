//TODO: need to move all tokens to a provider, so that we can inject our token functions here
//http://stackoverflow.com/questions/15666048/service-vs-provider-vs-factory
angular.module('sq.user.auth.token.interceptor',
  ['angular-jwt', 'sq.user.auth.tokens'])
.config(function($httpProvider, jwtInterceptorProvider) {
  //TODO: if this works, that means functions inside config have access to
  //everything exported from the dependencies
  
  /*@ngInject*/
  jwtInterceptorProvider.tokenGetter = function(userTokens) {
    return userTokens.idToken;
  };

  $httpProvider.interceptors.push('jwtInterceptor');
});
